/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from 'react-table';
// For default styles
import './table.css'

// This is a table general component which takes in JSON objects to define the headers (COLUMNS) and populate the cells (DATA). This table comes with pagination
// and sorting out of the box, as well as some optional features that are currently commented out. 
// For more information about this table and react-table in general, see https://www.youtube.com/watch?v=YwP4NAZGskg&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz

// For testing purposes
// import { COLUMNS } from './columns';
// import MOCK_DATA from './MOCK_DATA.json'
// const DATA = MOCK_DATA
// const Table = () => {

function onClickHandler(rowValue, func){
sessionStorage.setItem("selectedRowNetwork", rowValue);
console.log(sessionStorage.getItem("selectedRowNetwork"));
location.href = "/Networks";
func;
}

const Table = ({ COLUMNS, DATA }) => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/Networks`; 
    navigate(path);
  }


    const columns = useMemo(() => COLUMNS, [COLUMNS])
    const data = useMemo(() => DATA, [DATA])
    const [pageNum, setPageNum] = useState(0)
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      nextPage,
      previousPage,
      canPreviousPage,
      canNextPage,
      pageOptions,
      state,
      gotoPage,
      pageCount,
      setPageSize,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        disableSortRemove: true,  //optional, see https://stackoverflow.com/questions/64237143/make-react-table-sort-only-between-ascending-and-descending-order?rq=3 for more info
        initialState: { pageIndex: pageNum, sortBy: [{ id: 'columnId', desc: false }] },
        autoResetPage: false, 
        autoResetExpanded: false,
        autoResetGroupBy: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetRowState: false,
        
      },
      useSortBy,
      usePagination
    )
  
    const { pageIndex, pageSize } = state
      
    useEffect(() =>{
      setPageSize(20);
      setPageNum(state.pageIndex);
    }, [state.pageIndex]);

    return (
      <div className = 'table-containerStats'>
        <table id='TableStats' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? '▲'
                        : '▼'
                      : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}  onClick={() =>  row.values.cur_stage === 3 ? onClickHandler(row.values.name, routeChange) : '' }>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div>
          <button className = "prev-btn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button className = "prev-btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>{' '}
          <button className = "next-btn" onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>{' '}
          <button className = "next-btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          {/* OPTIONAL Go to page functionallity
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(pageNumber)
              }}
              style={{ width: '50px' }}
            />
          </span>{' '} */}
          {/* OPTIONAL Set page size dropdown
          <select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}>
            {[10, 25, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
        </div>
      </div>
    )
  }

export default Table;