import React, { useEffect, useState } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import ReactLoading from "react-loading";
import Table from "./Table.js";
import apiGetJobs from "api/apiGetJobs.js";
import apiLogin from "api/apiLogin.js";

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function dateTime(apiDate) {
  
  var offset = new Date().getTimezoneOffset(); //Accounts for local timezone
  const dateAPI = new Date(apiDate);
  const date = new Date(dateAPI.getTime()- (1*60*60*1000)*(offset/100 + 2)); //will have to adjust later

  let ampm = "AM"
  if (date.getHours()>11 ){
    ampm = "PM";
  }
  let hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  if (hours >12){ hours = hours-12;}
  if (hours === 0) { hours = 12;}
  
  return `${year}/${month}/${day} ${hours}:${minutes}${ampm}`;
}

const jobColumns = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "Date Started (Upload Time)",
    accessor: "date_ls_uploaded",
    // The formatting of the date is here rather than in FormatDashboardData because it would mess up the sorting otherwise
    Cell: ({ value }) => {
      return dateTime(value)
    }
  },
  {
    Header: "Date Completed",
    accessor: "date_job_completed",
    Cell: ({ value }) => {
      if (value === null) return "Not Completed";
      return dateTime(value);
    }
  },
  {
    Header: "scraperJob Rate Estimate [nodes/min]",
    accessor: "scraperjob_rate_est"
  },
  {
    Header: "scraperJob Rate Actual [nodes/min]",
    accessor: "scraperjob_rate_act"
  },
  {
    Header: "scraperJob Rate Error % ",
    accessor: "",
    Cell: ({ row })=>(      
      <span> 
          {
           (Math.abs(row.values.scraperjob_rate_act - row.values.scraperjob_rate_est)/row.values.scraperjob_rate_act * 100).toFixed(1) + "%"
          }
      </span>
      )
  },
  {
    Header: "scraperJob RunTime Estimate [minutes]",
    accessor: "scraperjob_runtime_est"
  },
  {
    Header: "scraperJob RunTime Actual [minutes]",
    accessor: "scraperjob_runtime_act"
  },
  {
    Header: "scraperJob RunTime Error % ",
    accessor: "",
    Cell: ({ row })=>(      
      <span> 
          {
           (Math.abs(row.values.scraperjob_runtime_act - row.values.scraperjob_runtime_est)/row.values.scraperjob_runtime_act * 100).toFixed(1) + "%"
          }
      </span>
      )
  },
  {
    Header: "networkJob Rate Estimate [nodes/min]",
    accessor: "networkjob_rate_est"
  },
  {
    Header: "networkJob Rate Actual [nodes/min]",
    accessor: "networkjob_rate_act"
  },
  {
    Header: "networkJob Rate Error % ",
    accessor: "",
    Cell: ({ row })=>(      
      <span> 
          {
           (Math.abs(row.values.networkjob_rate_act - row.values.networkjob_rate_est)/row.values.networkjob_rate_act * 100).toFixed(1) + "%"
          }
      </span>
      )
  },
  {
    Header: "networkJob RunTime Estimate [minutes]",
    accessor: "networkjob_runtime_est"
  },
  {
    Header: "networkJob RunTime Actual [minutes]",
    accessor: "networkjob_runtime_act"
  },
  {
    Header: "networkJob RunTime Error % ",
    accessor: "",
    Cell: ({ row })=>(      
      <span> 
          {
           (Math.abs(row.values.networkjob_runtime_act - row.values.networkjob_runtime_est)/row.values.networkjob_runtime_act * 100).toFixed(1) + "%"
          }
      </span>
      )
  },
  {
    Header: "totalRuntimeEST",
    accessor: "total_runtime_est"
  },
  {
    Header: "totalRuntimeACT",
    accessor: "total_runtime_act"
  },
  {
    Header: "totalRuntime Error % ",
    accessor: "",
    Cell: ({ row })=>(      
      <span> 
          {
           (Math.abs(row.values.total_runtime_act - row.values.total_runtime_est)/row.values.total_runtime_act * 100).toFixed(1) + "%"
          }
      </span>
      )
  }

];

function Stats() {
  const [ loggedIn, setLoggedIn ] = useState(false); //Is user logged into API
  const [ dashboardData, setDashboardData ] = useState([{}]) // Expects an array of objects (JSON)

  const { user } = useAuthenticator((context) => [context.user]);
  const email = user['attributes']['email']
  const password = user['attributes']['custom:password']

  sessionStorage.setItem("useremail",email);
  sessionStorage.setItem("password",password); 

  //Login to API backend
  useEffect(() => {
    apiLogin(setLoggedIn)
    },[]);

  //Runs apiGetJobs at page render that will rerun every 3 seconds according to the function
  useEffect(() => {
    const fetchData = async () => {
      await apiGetJobs(setDashboardData);
    };
    if (loggedIn) {
      fetchData();
    }
    const interval = setInterval(fetchData, 3000);
    return () => clearInterval(interval);
  }, [loggedIn]);

  return (
    <div id='Stats'>
      <div style={{display : 'flex', justifyContent : 'space-between'}}>
        <h2>Jobs Stats</h2>
        <button onClick={() => {apiGetJobs(setDashboardData)}} style={{height : '50%', marginTop : '30px', position : 'relative'}}>Update</button>
      </div>
      {(( dashboardData && dashboardData.length > 0 && !isObjectEmpty(dashboardData[0]) ) ) ?
        <Table COLUMNS={jobColumns} DATA={dashboardData}/> :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <h1> Loading... </h1>
          <ReactLoading type="bars" color="#0000FF" width="100px"/>
        </div>
      }
    </div>
  );
}

export default Stats;