import React from "react";
import { Helmet } from "react-helmet";

export default function Home(){
  return (
    <section id="intro">
      <Helmet>
      <title>AI-SNIPS Home Page</title>
        <meta
          name="description"
          content="Home page for the AISNIPS web application"
        />
        <meta
          name="keywords"
          content="AISNIPS, AI-SNIPS, aisnips, ai-snips, ai-snips.io, ai-snips.org, aisnips University of Houston, aisnips UHai-snips UH, ai-snips University of Houston"
        />
        <link rel="canonical" href={"https://www.ai-snips.org/"} />
        <meta property="og:title" content={"AISNIPS Home Page"} />
        <meta property="og:description" content={"Home page for the AISNIPS web application"} />
      </Helmet>
      <div className="container">
        <div className="intro-interior">
          <div className="intro-content">
            <h2>AISNIPS: AI Support for Network Intelligence based Pharmaceutical Security</h2>
          </div>
          <div className="intro-images">
            <img src={require('../img/round.webp')} className="rotating-image-forward image-1" alt="Rotating 1"/>
            <img src={require('../img/round2.webp')} className="rotating-image-backward image-2" alt="Rotating 2"/>
            <img src={require('../img/round3.webp')} className="rotating-image-forward-2 image-3" alt="Rotating 3"/>
          </div>
        </div>
      </div>
      <div id='home-body'>
        <div>
          <h1>Welcome to AISNIPS</h1>
        </div>
        <div>
          <p>
          AISNIPS (Physics-Based Approach to Illicit Drug Sales Network Disruption) is a forward-thinking initiative and comprehensive applied research project designed to combat illicit drug sales through innovative methods. 
          The critical components of the project include web scraping, machine learning, and network analysis, each contributing to a robust data pipeline.
          AISNIPS seeks to provide a unique and physics-based perspective for identifying and disrupting networks of illicit pharmaceutical sellers. By combining web scraping, machine learning, and network analysis, the project 
          aims to uncover patterns and connections that may not be apparent through traditional methods. AISNIPS emphasizes the importance of clear communication through data visualization by producing a visually 
          compelling representations of the modeling decisions made during the analysis. This will enable stakeholders, including pharmaceutical companies and law enforcement, to quickly understand the insights derived from the data.
          Lastly, AISNIPS recognizes the dynamic nature of illicit networks and, therefore, incorporates performance analysis into its framework. By continually assessing the network dynamics, the project aims to enhance its machine learning models, 
          ensuring they remain effective in identifying optimal points for disruption.
          </p>
        </div>
        </div>
    </section>
  )
}