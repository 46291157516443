import React from 'react';

function dateTime(cstDateTime) {
  // Assume cstDateTime is a string in the format 'YYYY-MM-DD HH:MM', already in CST
  const cstTime = new Date(`${cstDateTime}:00`); // No need to specify timezone offset

  // Formatting the date
  const year = cstTime.getFullYear();
  const month = String(cstTime.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(cstTime.getDate()).padStart(2, '0');

  // Formatting the time with AM/PM
  let hours = cstTime.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutes = String(cstTime.getMinutes()).padStart(2, '0');

  // Assembling the formatted date and time string with CST at the end
  return `${year}-${month}-${day} ${hours}:${minutes} ${ampm} CST`;
}



const Footer = () => (
  <footer id="Footer">
      <div id='FooterItem'>
        <div id ='FooterPadding'>Padding</div>
        <a href="https://uh.edu/cbl/" rel="noreferrer" target="_blank">
          <img src={require('../img/logo_cbl.png')} alt="logo_cbl" height={"100px"}/>  
        </a>
        <div id='Footer-logo-text'>
        v{process.env.REACT_APP_VERSION} | Released: {dateTime('2024-11-11 17:00')} | © AISNIPS Project 
        </div>
      </div>
      <div id='FooterItem'>
        <h2>
          Contact
        </h2>
        <p>
          Computational Biomedicine Lab
          <br/>
          c/o Ioannis A Kakadiaris
          <br/>
          University of Houston
          <br/>
          4349 MLK Blvd, Rm 322
          <br/>
          Houston, TX 77204-6022
        </p>
      </div>
      <div id='FooterItem'>
        <h2>
          Support
        </h2>
        <p>
          AI-SNIPS is based upon work supported in part by the National Science Foundation under Grant No. 2146335. Any opinions, findings, 
          and conclusions or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the 
          views of the National Science Foundation.
        </p>
        <br/>
        <p>
        Email: info@ai-snips.io
        </p>
      </div>
  </footer>
)

export default Footer;
