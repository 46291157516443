import React from 'react';
import ReportingIntakeForm from './reportingIntakeForm';
import DemoIntakeForm from './demoIntakeForm';

export default function AAB() {
    return (
        <div className='AAI_container'>
            <p>Hello, AAI Collaborator!</p>
            <DemoIntakeForm />
        </div>
    )
}