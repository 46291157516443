import axios from 'axios'
//TODO: find a way to implement useAuthenticator in this function and that way I won't have to include useAuthenticator on every page
//Function to login to api
const apiLogin = async( setLoggedIn ) =>{
  return await axios.post('https://api.ai-snips.io/api/users/login',
    {
      email: sessionStorage.getItem("useremail"),
      password: sessionStorage.getItem("password")
    }, 
    {
      timeout: 10000
    }
    )
    .then(function (response) 
    {
      if(response.data.success === true && response.status === 200){
        sessionStorage.setItem("authToken", response.data.token);
        console.log("You have successfully logged in.");
  
        setLoggedIn(true);
      }
      // console.log(response);
    })
    .catch(async function (error)
    {
      await new Promise((resolve,reject) => {
        setTimeout(() => {
          apiLogin(setLoggedIn);
          resolve();
        }, 3000)
      })
      console.log(error);
    });  
  };

  export default apiLogin;