import { View, Image, Text, Heading, Button, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

// Used to customize the Authenticator component. See https://ui.docs.amplify.aws/react/connected-components/authenticator/customization for more info. 
export const AuthComponents = {
    Header() {
    const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="AISNIPS logo"
            src={require("../img/ai-snips-logo-transparent-bg.png")}
          />
        </View>
      );
    },

    Footer() {
        const { tokens } = useTheme();
    
        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
              &copy; All Rights Reserved
            </Text>
          </View>
        );
      },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      // Including this breaks forgoten password functionallity, unclear why. 
      // Footer() {
      //   const { toForgotPassword } = useAuthenticator();
  
      //   return (
      //     <View textAlign="center">
      //       <Button
      //         fontWeight="normal"
      //         onClick={toForgotPassword}
      //         size="small"
      //         variation="link"
      //       >
      //         Reset Password
      //       </Button>
      //     </View>
      //   );
      // },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    // ConfirmSignUp: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Heading:
    //       </Heading>
    //     );
    //   },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    // },
    // MFA setup 
    // SetupTotp: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //       Enter Information:
    //       </Heading>
    //     );
    //   },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    // },
    ConfirmSignIn: {
      Header() {
        // const { tokens } = useTheme();
        return (
          <Heading
            // padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter MFA Code
          </Heading>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
    // ForgotPassword: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    // },
    // ConfirmResetPassword: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={3}
    //       >
    //         Enter Information:
    //       </Heading>
    //     );
    //   },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    // },
  };
  
  export const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password:',
        order: 1,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        // label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        // label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        // label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };