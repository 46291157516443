import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import ReactLoading from "react-loading";
import Table from "../../components/table/Table"
import apiLogin from "api/apiLogin";
import apiGetJobs from "api/apiGetJobs";
import apiGetPeriodicJobs from "api/apiGetPeriodicJobs";
import apiDeleteJobs from "api/apiDeleteJobs";

//TODO: I have way too many time functions here because i keep having to adjust specific things. When this project reaches a good stopping point clean this up
function processTime(startScraper, endScraper, startNetwork, endNetwork){
  const dateStartScraper = new Date(startScraper);
  const dateEndScraper = new Date(endScraper);
  const dateStartNetwork = new Date(startNetwork);
  const dateEndNetwork = new Date(endNetwork);
  const diffScraperMilliseconds = dateEndScraper - dateStartScraper;
  const diffNetworkMilliseconds = dateEndNetwork - dateStartNetwork;

  const diffMilliseconds = diffScraperMilliseconds + diffNetworkMilliseconds
  const diffMinutes = diffMilliseconds / (1000*60);
  const hoursPassed = Math.floor(diffMinutes / 60);
  const minutesPassed = diffMinutes % 60;
  const timePassed = hoursPassed * 60 + minutesPassed;
  const timePassedHours = Math.trunc(timePassed/60);

  let hoursString = timePassedHours.toString();
  if (hoursString > '1') hoursString = hoursString + ' hours';
  if (hoursString === '1') hoursString = hoursString + ' hour';
  if (hoursString <= '0') hoursString = '';
  const timePassedMinutes = (timePassed % 60).toFixed(1).toString();
  let minutesString = timePassedMinutes.toString();
  if (timePassedMinutes > 1) minutesString = minutesString + " minutes";
  if (timePassedMinutes === '1.0') minutesString = minutesString + " minute";
  if (timePassedMinutes < 1) minutesString = minutesString + ' minutes';
  if (timePassedMinutes <= 0)minutesString = ''
  // console.log(timePassedMinutes)
  const timePassedString = hoursString + ' ' + minutesString;

  return timePassedString;
}

function dateTime(apiDate) {
  
  var offset = new Date().getTimezoneOffset(); //Accounts for local timezone
  const dateAPI = new Date(apiDate);
  const date = new Date(dateAPI.getTime()- (1*60*60*1000)*(offset/100 + 2)); //will have to adjust later to automatically grab timezone offset

  let ampm = "AM"
  if (date.getHours()>11 ){
    ampm = "PM";
  }
  let hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  if (hours >12){ hours = hours-12;} //if past 12PM, for example 1PM then instead of 13, it'll display 1
  
  if (Math.floor(hours) === 0) { hours = 12;} //for 12AM

  return `${year}/${month}/${day} ${hours}:${minutes}${ampm}`;
}

function etcEstimator(startTime, etc){
const dateStart = new Date(startTime);
const dateETC = new Date(startTime);
dateETC.setMinutes(dateETC.getMinutes() + Math.ceil(etc));
const currentTime = new Date(Date.UTC(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  new Date().getHours(),
  new Date().getMinutes(),
  new Date().getSeconds()));

currentTime.setHours(currentTime.getHours() + 10);
const diffMilliseconds = currentTime - dateStart;
const diffMinutes = diffMilliseconds / (1000*60);
const hoursPassed = Math.floor(diffMinutes / 60);
const minutesPassed = (diffMinutes % 60);
const timePassed = hoursPassed * 60 + minutesPassed;
return timePassed;
}

function etcCountdown(startTime, etc){
  const dateStart = new Date(startTime);
  const dateETC = new Date(startTime);
  dateETC.setMinutes(dateETC.getMinutes() + Math.ceil(etc));
  const currentTime = new Date(Date.UTC(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes(),
    new Date().getSeconds()));
  currentTime.setHours(currentTime.getHours() + 10);
  const diffMilliseconds = currentTime - dateStart;
  const diffMinutes = diffMilliseconds / (1000*60);
  const hoursPassed = Math.floor(diffMinutes / 60);
  const minutesPassed = Math.ceil(diffMinutes % 60);
  const timePassed = hoursPassed * 60 + minutesPassed;
  const timePassedTotal = Math.ceil(Math.ceil(etc) - (timePassed))
  const timePassedHours = Math.trunc(timePassedTotal/60);
  
  let hoursString = timePassedHours.toString();
  if (hoursString > '1') hoursString = hoursString + ' hours';
  if (hoursString === '1') hoursString = hoursString + ' hour';
  if (hoursString <= '0') hoursString = '';
  const timePassedMinutes = Math.ceil(timePassedTotal%60);
  let minutesString = timePassedMinutes.toString();
  if (timePassedMinutes > 1) minutesString = minutesString + " minutes";
  if (timePassedMinutes === 1) minutesString = minutesString + " minute";
  if (timePassedMinutes <= 0) minutesString = '';
  const timePassedString = hoursString + ' ' + minutesString;
  if (hoursString === '' && minutesString === ''){  return '-';}

  return timePassedString;
}

function timeElapsed(startTime, finishTime){
  const dateStart = new Date(startTime);
  const dateFinish = new Date(finishTime);
  const currentTime = new Date(Date.UTC(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes(),
    new Date().getSeconds()));
  currentTime.setHours(currentTime.getHours() + 10);
  const diffMilliseconds = dateFinish - dateStart;
  const diffMinutes = diffMilliseconds / (1000*60);
  const hoursPassed = Math.floor(diffMinutes / 60);
  const minutesPassed = diffMinutes % 60;
  const timePassed = hoursPassed * 60 + minutesPassed;
  const timePassedHours = Math.trunc(timePassed/60);

  let hoursString = timePassedHours.toString();
  if (hoursString > '1') hoursString = hoursString + ' hours';
  if (hoursString === '1') hoursString = hoursString + ' hour';
  if (hoursString <= '0') hoursString = '';
  const timePassedMinutes = (timePassed%60).toFixed(1);
  let minutesString = timePassedMinutes.toString();
  if (timePassedMinutes > 1) minutesString = minutesString + " minutes";
  if (timePassedMinutes === 1) minutesString = minutesString + " minute";
  if (timePassedMinutes <= 0) minutesString = '';
  const timePassedString = hoursString + ' ' + minutesString;

  return timePassedString;
}

function liveTimeElapsed(startTime){
  const dateStart = new Date(startTime);
  const currentTime = new Date(Date.UTC(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes(),
    new Date().getSeconds()));
  currentTime.setHours(currentTime.getHours() + 10);
  const diffMilliseconds = currentTime - dateStart;
  const diffMinutes = diffMilliseconds / (1000*60);
  const hoursPassed = Math.floor(diffMinutes / 60);
  const minutesPassed = Math.ceil(diffMinutes % 60);
  const timePassed = hoursPassed * 60 + minutesPassed;
  const timePassedHours = Math.trunc(timePassed/60);

  let hoursString = timePassedHours.toString();
  if (hoursString > '1') hoursString = hoursString + ' hours';
  if (hoursString === '1') hoursString = hoursString + ' hour';
  if (hoursString <= '0') hoursString = '';
  const timePassedMinutes = Math.ceil(timePassed%60);
  let minutesString = timePassedMinutes.toString();
  if (timePassedMinutes > 1) minutesString = minutesString + " minutes";
  if (timePassedMinutes === 1) minutesString = minutesString + " minute";
  if (timePassedMinutes <= 0) minutesString = '';
  const timePassedString = hoursString + ' ' + minutesString;

  return timePassedString;
}

function minToHoursMin ( time ) {
  //Time in minutes
  const hoursPassed = Math.floor(time/60);
  const minutesPassed = (time % 60).toFixed(1);
  let hoursString = hoursPassed.toString();
  
  if (hoursString > '1') hoursString = hoursString + ' hours';
  if (hoursString === '1') hoursString = hoursString + ' hour';
  if (hoursString <= '0') hoursString = '';

  let minutesString = minutesPassed.toString();
  if (minutesPassed > 0) minutesString = minutesString + " minutes";
  if (minutesPassed <= 0) minutesString = '';
  const timePassedString = hoursString + ' ' + minutesString;

  return timePassedString;
}

function currentStage(num) {
  if (num === 0) return "Not Started";
  if (num === 1) return "Web Scraping";
  if (num === 2) return "Network Generation";
  if (num === 3) return "Complete";
      return "Error";
}

function currentStatus(num){
  if (num === -10) return "Cancelled";
  if (num === -1) return "Error";
  if (num === 0) return "Pending";
  if (num === 1) return "Running";
  if (num === 2) return "Complete";
}

const jobColumns = [
  {
    Header: "totalRuntimeEST",
    accessor: "total_runtime_est"
  },
  {
    Header: "networkJobRuntimeEST",
    accessor: "networkjob_runtime_est"
  },
  {
    Header: "scraperjob_start_date",
    accessor: "date_scraperjob_started"
  },
  {
    Header: "scraperjob_compeleted_date",
    accessor: "date_scraperjob_completed"
  },
  {
    Header: "scraperjob_queued_date",
    accessor: "date_scraperjob_queued"
  },
  {
    Header: "networkjob_start_date",
    accessor: "date_networkjob_started"
  },
  {
    Header: "networkjob_completed_date",
    accessor: "date_networkjob_completed"
  },
  {
    Header: "networkjob_queued_date",
    accessor: "date_networkjob_queued"
  },
  {
    Header: "ScraperJobsBlob",
    accessor: "scraperjobs"
  },
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "Dynamic ID",
    accessor: "dynamic_job_id"
  },
  {
    Header: "Job Name",
    accessor: "name",
  },
  {
    Header: "Leadsheet Name",
    accessor: "original_ls_filename",
  },
  {
    Header: "Date Started (Upload Time)",
    accessor: "date_ls_uploaded",
    // The formatting of the date is here rather than in FormatDashboardData because it would mess up the sorting otherwise
    Cell: ({ value }) => {
      return dateTime(value)
    }
  },
  {
    Header: "Date Completed",
    accessor: "date_job_completed",
    Cell: ({ value }) => {
      if (value === null) return "Not Completed";
      return dateTime(value);
    }
  },
  {
    Header: "Stage",
    accessor: "cur_stage",
    Cell :({value}) =>{
      return currentStage(value);
    }
  }, 
  {
    Header: "Status",
    accessor: "cur_status",
    Cell :({value}) =>{
      return currentStatus(value);
    }
  },
  {
    Header: "Progress",
    accessor: 'percent_complete',
    Cell: ({ row }) => (
          <span>
            {
              (row.values.percent_complete === 100 || row.values.cur_stage === 3 ) ? 
                <progress id = "progress-bar" aria-label = "Job progress" value = {100} max = "100"></progress> : 
              ((row.values.cur_stage === 2 || row.values.cur_stage === 1 ) && etcCountdown(row.values.date_scraperjob_started, row.values.total_runtime_est) !== '-') ?
                <progress id = "progress-bar" aria-label = "Job progress" value = {etcEstimator( row.values.date_scraperjob_started,row.values.total_runtime_est )} max = {row.values.total_runtime_est}></progress> :
              (row.values.cur_stage === 0 || row.values.date_scraperjob_started === null ) ? 
                <progress id = "progress-bar" aria-label = "Job progress" value = {0} max = {100}></progress> : 
                <progress id = "progress-bar" aria-label = "Job progress" value = {80} max = {100}></progress>
            }
          </span>
    )
  },
  {
    Header: "ETC",
    accessor: "scraperjob_runtime_est",
    Cell: ({ row })=>(      
    <span> 
        {
          row.values.cur_stage === 3 ?
          '-' : 
          (etcCountdown(row.values.date_scraperjob_started, row.values.total_runtime_est))
        }
    </span>
    )
  },
  {
    Header: "Time Queued",
    accessor: "",
    Cell: ({ row })=>(      
    <span> 
        {
        ( row.values.cur_stage === 3 ) ? processTime(row.values.date_scraperjob_queued, row.values.date_scraperjob_started, row.values.date_networkjob_queued, row.values.date_networkjob_started) : '-' 
        }
    </span>
    )
    
  },
  {
    Header: "Time Running",
    accessor: "total_runtime_act",
    Cell: ({ row })=>(      
    <span> 
        {
          //Todo: can make it more dynamic
          ( row.values.cur_stage === 3 ) ? processTime(row.values.date_scraperjob_started, row.values.date_scraperjob_completed, row.values.date_networkjob_started, row.values.date_networkjob_completed) : '-'
        }
    </span>
    )
  },
  {
    Header: "Total_Time",
    accessor: "",
    Cell: ({ row })=>(      
    <span> 
        {
        ( row.values.cur_stage === 3 ) ? processTime(row.values.date_scraperjob_queued, row.values.date_scraperjob_completed, row.values.date_networkjob_queued, row.values.date_networkjob_completed) : '-'
        }
    </span>
    )
  }
];

const periodicJobColumns = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "Job Name",
    accessor: "name",
  },
  {
    Header: "Leadsheet Name",
    accessor: "original_ls_filename",
  },
  {
    Header: "Date Created",
    accessor: "date_created",
    // The formatting of the date is here rather than in FormatDashboardData because it would mess up the sorting otherwise
    Cell: ({ value }) => {
      return dateTime(value)
    }
  },
  {
    Header: "Date of Last Run",
    accessor: "date_last_run",
    Cell: ({ value }) => {
      if (value === null) return "Not Completed";
      return dateTime(value);
    }
  },
  {
    Header: "Date of Next Run",
    accessor: "date_next_run",
    Cell: ({ value }) => {
      if (value === null) return "Not Completed";
      return dateTime(value);
    }
  },
  {
    Header:'Interval Between Runs',
    accessor: "interval_days",
    Cell: ({value}) => {
      return value + "days"
    }
  }
];

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function JobDashboard() {
  const [ dashboardData, setDashboardData ] = useState([{}]); // Expects an array of objects (JSON)
  const [ periodicDashboardData, setPeriodicDashboardData ] = useState([{}]); //Expects an array of objects (JSON)
  const [ loggedIn, setLoggedIn ] = useState(false); //Is User Logged into API
  const [ periodic, setPeriodic ] = useState(false); //True = see periodic dashboard :: False = see jobs dashboard
  const [ selectedRowData, setSelectedRowData ] = useState([{}]);
  const [ parsedRowData, setParsedRowData ] = useState([{}]);
  const [ selectedRowIds, setSelectedRowIds ] = useState([]);
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true);
  const [ deleteLeadsheetValue, setDeleteLeadsheetValue ] = useState('yes');
  const [ deleteScraperJobValue, setDeleteScraperJobValue ] = useState('yes');
  const [ flipDeleteBool, setFlipDeleteBool ] = useState(false);
  const [ canDelete, setCanDelete ] = useState(false);
  // The cognito:groups key has the value of an array containing all the groups of which the user has membership.




  const handleDropdownChange = (event) => {
    const { name, value } = event.target;

    // Store the selected value in state
    if (name === 'deleteLeadsheet') {
      setDeleteLeadsheetValue(value);
    } else if (name === 'deleteScraperJob') {
      setDeleteScraperJobValue(value);
    }
  };

  const { user } = useAuthenticator((context) => [context.user]);
  const email = user['attributes']['email']
  const password = user['attributes']['custom:password']
  const group = user["signInUserSession"]["idToken"]['payload']['cognito:groups']
  function checkUser(groupName){
    if (groupName === 'admin' || groupName === 'researcher'){ setCanDelete(true); }
    console.log(groupName)
  }

    useEffect(() => {
      checkUser(group[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canDelete]);

  sessionStorage.setItem("useremail",email);
  sessionStorage.setItem("password",password); 

  let navigate = useNavigate(); 
    const routeChange = () =>{ 
    let path = `/Stats`; 
    navigate(path);
  }

  //Login if not logged in and stop trying when logged in, will run twice because application is in strict mode
  useEffect(() => {
    apiLogin(setLoggedIn)
  },[]);

  //Runs apiGetJobs at page render that will rerun every 10 seconds according to the function. This runs twice because of strict mode. 
  useEffect(() => {
    const fetchData = async () => {
      await apiGetJobs(setDashboardData);
      await apiGetPeriodicJobs(setPeriodicDashboardData);
    };
    if (loggedIn) {
      fetchData();
    }
    const interval = setInterval(fetchData, 3000);
    return () => clearInterval(interval);
  },[loggedIn]);
  
  useEffect(()=>{
    const parsedData = selectedRowData.map(item=>item.original);
    setParsedRowData(parsedData);
  }, [selectedRowData, periodic] )

  useEffect(()=>{
    const parsedRowDataId = Array.isArray(parsedRowData) && parsedRowData.length > 0 ? parsedRowData.map(item => item && item.id) : [];
    setSelectedRowIds(parsedRowDataId);
  }, [parsedRowData, periodic] )

  useEffect(() =>{
    let disable = false
    disable = ((selectedRowIds.length === 0 || selectedRowIds[0]===undefined || !canDelete))
    setIsButtonDisabled(disable)
  },[selectedRowIds, periodic, canDelete])

  return (
    <div id='JobsDashboard'>
 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  <div>
    <h2>{periodic ? 'Periodic ' : ''} Jobs</h2>
    {!periodic ? <h4 style={{ marginTop: '0px' }} >Please click on a completed job to take you to the network view. Jobs can only be deleted as admin or researcher.</h4> : ''}
  </div>
  <div>
    <button
      onClick={() => {
        apiDeleteJobs(selectedRowIds, deleteLeadsheetValue, deleteScraperJobValue, periodic);
        setSelectedRowData([]);
        setFlipDeleteBool(!flipDeleteBool);
      }}
      disabled={isButtonDisabled}
      style={{ height: '50%', marginTop: '30px', marginRight: '12px', position: 'relative' }}
    >
      Delete Selected Jobs
    </button>
    {!isButtonDisabled && (
      <>
        <label htmlFor="deleteLeadsheet">Delete Leadsheet: </label>
        <select id="deleteLeadsheet" name="deleteLeadsheet" onChange={handleDropdownChange} style={{ marginRight: '5px' }}>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>

        <label htmlFor="deleteScraperJob">Delete ScraperJob Data: </label>
        <select id="deleteScraperJob" name="deleteScraperJob" onChange={handleDropdownChange} style={{ marginRight: '5px' }}>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </>
    )}
    <button onClick={() => {
      setPeriodic(!periodic);
      setSelectedRowData([]);
      setFlipDeleteBool(!flipDeleteBool);
    }} style={{ height: '50%', marginTop: '30px', marginRight: '12px', position: 'relative' }}>
      {periodic ? 'Jobs' : 'Periodic Jobs'}
    </button>
    <button onClick={routeChange} style={{ height: '50%', marginTop: '30px', marginRight: '12px', position: 'relative' }}>Stats</button>
    <button onClick={() => apiGetJobs(setDashboardData)} style={{ height: '50%', marginTop: '30px', position: 'relative' }}>Update</button>
  </div>
</div>

      <div id = 'JobsTable'>
        {(( !periodic && dashboardData && dashboardData.length > 0 && !isObjectEmpty(dashboardData[0]) ) ) ?
          <Table COLUMNS={jobColumns} DATA={dashboardData} onRowSelectStateChange={setSelectedRowData} clearRows={flipDeleteBool}/> :
            ( periodic && periodicDashboardData && periodicDashboardData.length > 0 && !isObjectEmpty(periodicDashboardData[0]) )?
            <Table COLUMNS={periodicJobColumns} DATA={periodicDashboardData} onRowSelectStateChange={setSelectedRowData} clearRows={flipDeleteBool}/> :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <h1> Loading... </h1>
            <ReactLoading type="bars" color="#0000FF" width="100px"/>
          </div>}
      </div>
    </div>
  );
}

export default JobDashboard;