import React, { useState, useEffect, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

 function Documentation() {

  const [ pdfFile, setpdfFile ] = useState();
  const [ numPages, setNumPages ] = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ loading, setLoading ] = useState(true);


  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],);

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  async function getPDF() {
    pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js';
    const pdf_path = "Documents/AISNIPS-data-dictionary-Full-PROPRIETARY_20240422.pdf"
    setLoading(true)
    const pdf = await Storage.get(pdf_path)
    // console.log(pdf);
    setpdfFile(pdf);
    setLoading(false)
  }

  // eslint-disable-next-line
  useEffect(() => {getPDF()}, [])

  return (
    <div id='DocumentationPage'>
      <div id='DocumentationHeader'>
        <h1>Data Dictionary</h1>
      </div>
      {(loading) ? 
      ( <ScaleLoader></ScaleLoader>) :
       (
        <div id = 'DocumentationBody'>
            <div style = {{    
                  flexDirection: "column",
                  backgroundColor: "#F6F8FA",
                  display: "flex",
                  border: "1px solid black",
                  width: "100%",
                  padding: "26px 20px",
                  height: "65vh",
                  overflow: "scroll",
                  }}>
            <Document file = {pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber = {pageNumber}/>
            </Document>
            </div>
            <div>
                <button style = {{margin: "5px"}} disabled={pageNumber <= 1} onClick={previousPage} type="button">  Previous  </button>
                <span>  {`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`} </span>
                <button style = {{margin: "5px"}} disabled={pageNumber >= numPages} onClick={nextPage}  type="button">  Next  </button>
            </div>
        </div>
      )}
    </div>
  )
}
export default Documentation