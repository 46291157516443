import React, { useEffect, useState } from 'react';
import { XMLParser } from 'fast-xml-parser';
import axios from 'axios';

const IntakeFormBuilder = ({ xmlFilePath, handleSubmit, title }) => {
  const [formElements, setFormElements] = useState([]);

  useEffect(() => {
    // Fetch and parse XML file
    const fetchAndParseXML = async () => {
      try {
        const { data } = await axios.get(xmlFilePath);

        // Initialize the parser
        const parser = new XMLParser();
        
        // Parse the XML data to a JavaScript object
        const parsedXML = parser.parse(data);

        // Convert the XML structure into an array of form elements
        const inputs = parsedXML.form.input.map(input => {
          // Handle select (dropdown) and radio types with options
          if (input.type === 'select' || input.type === 'radio') {
            const options = Array.isArray(input.options.option)
              ? input.options.option  // If options is an array, use it as is
              : [input.options.option];  // If not, wrap it in an array

            return {
              type: input.type,
              label: input.label,
              name: input.name,
              options,  // Store options as an array
            };
          }
          
          // Handle standard input types
          return {
            type: input.type,
            label: input.label,
            name: input.name,
          };
        });

        setFormElements(inputs);
      } catch (error) {
        console.error("Error loading XML file", error);
      }
    };

    fetchAndParseXML();
  }, [xmlFilePath]);

  const defaultHandleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log("Form submitted:", data);
  };

  const onSubmit = handleSubmit ? handleSubmit : defaultHandleSubmit;

  return (
    <form className="IntakeFormBuilder" onSubmit={onSubmit}>
      <h2>{title}</h2>
      {formElements.map((input, index) => (
        <div key={index}>
          <label>
            {input.label}
            {input.type === 'select' ? (
              <select name={input.name}>
                {input.options.map((option, idx) => (
                  <option key={idx} value={option}>{option}</option>
                ))}
              </select>
            ) : input.type === 'radio' ? (
              <div>
                {input.options.map((option, idx) => (
                  <label key={idx} style={{ marginRight: "10px" }}>
                    <input
                      type="radio"
                      name={input.name}
                      value={option}
                    />
                    {option}
                  </label>
                ))}
              </div>
            ): (
              <input type={input.type} name={input.name} />
            )}
          </label>
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
  };
export default IntakeFormBuilder;



// <form className='IntakeFormBuilder' onSubmit={onSubmit}>
// <h1>{title}</h1>
// {formElements.map((input, index) => (
//   <div key={index} style={{ marginBottom: '1rem' }}>
//     <label>
//       {input.label}
//       <input type={input.type} name={input.name} />
//     </label>
//   </div>
// ))}
// <button type="submit">Submit</button>
// </form>