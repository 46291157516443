import React, { useContext, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Button, ButtonGroup, Flex, Spacer } from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import AttributePaneGallery from './AttributePaneGallery'
import { ClusterContext } from './ClustersPanel';
import axios from 'axios';

async function apiGetWordCloud (networkName: null|string, cid: string, setMyCallback: Dispatch<SetStateAction<string>>){
  const apiCallPrepend = 'https://api.ai-snips.io/api/networks/';
  const apiCallNetworkName = networkName;
  const apiCallCID = '/cid/' + cid;
  const apiCallAppend = '?img_gallery_size=original';

  const apiCall = apiCallPrepend + apiCallNetworkName + apiCallCID + apiCallAppend;
  return await axios.get(apiCall,
  {
    headers: {
      accept : 'application/json',
      Authorization : sessionStorage.getItem("authToken"),
    },
    timeout: 10000
  })
  .then(function(response){
      if (response.data.data) {
      setMyCallback(response.data.data);
      return response.data.data;
      }
  })
  .catch(async function(error){
    console.error(error)
    if ( error.code === "ERR_NETWORK" ) {
      await new Promise<void>((resolve,reject) => {
        setTimeout(() => {
          apiGetWordCloud(networkName, cid, setMyCallback);
          resolve();
        }, 3000)
      })
    }
  })
}

interface JsonResponse {
  image_format: string;
  image_gallery: {
    count: number;
    image: {
      [key: string]: string;
    };
  };
  num_clusters: number;
  word_clouds: {
    address: string;
    count: number;
    domain: string;
    email: string;
    keyword: string;
    phone: string;
  };
}

function parseWordCloudString(wordCloudString: string, setMyCallback: Dispatch<SetStateAction<JsonResponse | null>>){
  const jsonData: JsonResponse = JSON.parse(wordCloudString);
  setMyCallback(jsonData);
}

interface AttributePaneProps {
  open: boolean;
  onClose: () => void;
  finalRef: any;
  clabel: string;
  networkName: string;
  hasCluster: boolean;
  // wordCloudString: string;
}

export default function AttributePaneDrawer({ open, onClose, finalRef, clabel, networkName, hasCluster}: AttributePaneProps) {

  const clusterInfo = useContext(ClusterContext)
  const sortable: [string, number][] = Object.entries(clusterInfo);
  const sortedClusters = sortable.sort((a, b) => b[1] - a[1]);
  const sortedLabels = sortedClusters.map(x => x[0])
  const [ clusterLabel, setClusterLabel ] = useState(clabel)
  const [ wordCloudParsed, setWordCloudParsed ] = useState<JsonResponse | null> (null);
  const [ wordCloudString, setWordCloudString ] = useState('');

  useEffect(()=>{
    setClusterLabel(clabel);
  },[clabel]);

  function prevCluster() {
    setClusterLabel((prevLabel: string | number) => {
      if(typeof(prevLabel) === 'number'){ prevLabel=prevLabel.toString() }
      const currentIndex = sortedLabels.indexOf(prevLabel);
      const nextIndex = (currentIndex + 1) % sortedLabels.length;
      return sortedLabels[nextIndex];
    });
  }

  function nextCluster() {
    setClusterLabel((prevLabel: string | number) => {
      if(typeof(prevLabel) === 'number'){ prevLabel=prevLabel.toString() }
      const currentIndex = sortedLabels.indexOf(prevLabel);
      const prevIndex = (currentIndex - 1 + sortedLabels.length) % sortedLabels.length;
      return sortedLabels[prevIndex];
    });
  }
  
  useEffect(()=>{ 
      apiGetWordCloud(networkName, clusterLabel, setWordCloudString)
    },[clusterLabel, networkName, hasCluster])

  useEffect(() =>{
    if (wordCloudString){
      parseWordCloudString(wordCloudString, setWordCloudParsed);
    }
  },[clusterLabel, wordCloudString])

  if (!open) return null
  return (
<>
  <Drawer
    id='AttributePaneDrawer'
    isOpen={open}
    placement='left'
    onClose={onClose}
    finalFocusRef={finalRef}
    size='lg'
  >
    <DrawerOverlay bg='rgb(255 255 255 / 100%)' />
    <DrawerContent>
      <DrawerCloseButton style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '18px', cursor: 'pointer' }} />
      <DrawerHeader style={{ fontSize: '20px', fontWeight: 'bold', padding: '20px' }}>
        <br/><br/>More Information on Cluster {clusterLabel}  [{clusterInfo[clusterLabel]} Sellers]
      </DrawerHeader>

      <DrawerBody style={{ overflowY: 'auto', paddingLeft: '20px' }}>
        <AttributePaneGallery clabel={clusterLabel} wordCloudStringJSON={wordCloudParsed}/>
      </DrawerBody>

      <DrawerFooter>
        <Flex direction='row' width='100%'>
          <ButtonGroup spacing='2'style={{paddingLeft: '15px'}}>
            <Button style={{ backgroundColor: 'transparent', border: '1px solid #ccc', color: '#333', padding: '10px 20px', fontSize: '16px', cursor: 'pointer', borderRadius: '8px' }} onClick={prevCluster}>
              Previous Cluster
            </Button>
            <Button style={{ backgroundColor: 'transparent', border: '1px solid #ccc', color: '#333', padding: '10px 20px', fontSize: '16px', cursor: 'pointer', borderRadius: '8px' }} onClick={nextCluster}>
              Next Cluster
            </Button>
          </ButtonGroup>
          <Spacer />
          <Button variant='outline' mr={3} onClick={onClose} style={{ border: '1px solid #ccc', color: '#333', padding: '10px 20px', fontSize: '16px', cursor: 'pointer', borderRadius: '8px' }}>
            Exit
          </Button>
        </Flex>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
</>)
}