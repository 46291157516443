// WORK IN PROGRESS 
import React, { forwardRef, useState, Dispatch, SetStateAction, useEffect } from 'react'
import AttributePaneDrawer from "./AttributePaneDrawer";
import { AiFillInfoCircle } from "react-icons/ai";
import { IconButton,useDisclosure } from "@chakra-ui/react";
import axios from 'axios';




interface MoreInfoProps {
    clabel : string;
    ref : React.RefObject<HTMLButtonElement>;
    selectedNetwork: string;
    hasCluster: boolean;
}


    
const MoreInfoButton = forwardRef((props : MoreInfoProps,ref: any) => {
    const { isOpen : isAPOpen, onOpen : onAPOpen, onClose : onAPClose } = useDisclosure()
    function onClickHandler() {
        onAPOpen();
    }

    return (
        <>  
            <IconButton aria-label='More Info Button' ref={props.ref} size="sm" onClick={onClickHandler} icon={<AiFillInfoCircle />} />
            <AttributePaneDrawer hasCluster = {props.hasCluster} networkName = {props.selectedNetwork} open = { isAPOpen } onClose={onAPClose} finalRef={ref} clabel={props.clabel}/>
        </>
    )
})

export default MoreInfoButton;