import React from 'react';
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div id='About'>
      <Helmet>
      <title>AI-SNIPS About Page</title>
        <meta
          name="description"
          content="About page for the AISNIPS web application"
        />
        <meta
          name="keywords"
          content="AISNIPS, AI-SNIPS, aisnips, ai-snips, ai-snips.io, ai-snips.org, aisnips University of Houston, aisnips UH, ai-snips UH, ai-snips University of Houston"
        />
        <link rel="canonical" href={"https://www.ai-snips.org/About"} />
        <meta property="og:title" content={"AISNIPS About Page"} />
        <meta property="og:description" content={"About page for the AISNIPS web application"} />
      </Helmet>
      <header>About AISNIPS</header>
        <div id='AboutBody'>
          <h2>
            <strong>Project Description:</strong> AISNIPS is a physics based approach to curbing illicit drug sales.  
          </h2>

          <h2><strong>Key Components:</strong></h2>
          <ul>
            <li><strong>Web Scraping:</strong> AISNIPS programatically scrapes the web to collect data on suspected sellers.</li>
            <li><strong>Machine Learning:</strong> AISNIPS employs various cutting edge Machine Learning techniques to process and analyze data.</li>
            <li><strong>Network Analysis:</strong> AISNIPS casts the data into a network and applies our physics informed models to identify the best points of disruption.</li> 
          </ul>

          <h2><strong>Project Goals:</strong></h2>
          <ul>
            <li>&#x2022; Provide a novel perspective for finding the best ways to disrupt networks of illicit pharmaceutical sellers.</li>
            <li>&#x2022; Produce a visualization of data which clearly communicates our modeling decisions.</li>
            <li>&#x2022; Perform analysis on dynamics of a network to improve our models for identifying disruption points.</li>
          </ul>

          <h2>
            <strong>Summary:</strong> AISNIPS is an efficient data pipeline for pharmaceutical stakeholders and law enforcement, providing a physics-based perspective on the best ways to identify and disrupt networks of illicit pharmaceutical sellers.
          </h2>
        </div>
    </div>
  )
}
