import  { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ReactPlayer from 'react-player';
import { nAWSVid, setAWSVidVariable } from '../App';
import ReactLoading from "react-loading";



export default function HowTo() {
  const [AWSVid, setAWSVid] = useState(false);
  const [firstVideoUrl, setFirstVideoUrl] = useState('');
  const [secondVideoUrl, setSecondVideoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuthenticator((context) => [context.user]);
  const email = user['attributes']['email'];
  const group = user["signInUserSession"]["idToken"]['payload']['cognito:groups'];
  const userID = user["signInUserSession"]["idToken"]['payload']['cognito:username'];

  function checkUser(groupName) {
    if (groupName === 'admin' || groupName === 'researcher') {
      setAWSVidVariable(true)
      setAWSVid(true);
    }
    else{
      setAWSVidVariable(false)
    }
  }

  const fetchVideoUrl = async (objectName) => {
    try {
      const response = await fetch(`https://hqsp8mpv87.execute-api.us-east-1.amazonaws.com/dev-aisnips?object_name=${objectName}&user_id=${userID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch the video URL');
      }
      const data = await response.json();
      return data.url;
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    checkUser(group[0]);

    const fetchUrls = async () => {
      const firstUrl = await fetchVideoUrl('This_guide_provides_a_step_by_step_walkthrough_of_using_the_AISNIPS_website.mp4');
      setFirstVideoUrl(firstUrl);

      const secondUrl = await fetchVideoUrl('How_to_support_AISNIPS_Website.mp4');
      setSecondVideoUrl(secondUrl);
      setIsLoading(false);
    };

    fetchUrls();
  }, [nAWSVid]);

  const handleContextMenu = (event) => {
    event.preventDefault();  // Prevent the default context menu
  };

  return (
    <div id="HowTo-Container">
      {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <h1> Loading... </h1>
            <ReactLoading type="bars" color="#0000FF" width="100px"/>
          </div> : (
        <div id="HowTo-Body">
          {/* <div>Hello {email}, you are part of the group {group}</div> */}
          <div id="HowTo-Videos" style={{ display: "flex" }}>
            <div style={{ flex: 1, marginRight: "10px" }}>
              <h1>AI-SNIPS User Video</h1>
              <div id="video-container" style={{ position: "relative", paddingBottom: "35%" }}>
                <ReactPlayer
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  url={firstVideoUrl}
                  controls={true} // true if you want default controls, false if not
                  playing={false} // Set this based on your app's state or user interactions
                  width= {nAWSVid ? '100%' : '70%'}
                  height={nAWSVid ? '100%' : '70%'}
                  onContextMenu={handleContextMenu}
                  
                />
              </div>
            </div>
            {nAWSVid && (
              <div style={{ flex: 1, marginLeft: "10px" }}>
                <h1>AI-SNIPS Admin Video</h1>
                <div id="video-container" style={{ position: "relative", paddingBottom: "35%" }}>
                  <ReactPlayer
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    url={secondVideoUrl}
                    controls={true} // Adjust as needed
                    playing={false} // Manage autoplay
                    width='100%'
                    height='100%'
                    onContextMenu={handleContextMenu}
                  />
                </div>
              </div>
            )}
          </div>
          {nAWSVid && (
            <div id="HowToAPIDocs">
              <h2>Link to API Swagger Docs: </h2> <a href='https://api.ai-snips.io/api/'>https://api.ai-snips.io/api/</a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
