import React from 'react';
import { Grid, GridItem, Image } from "@chakra-ui/react";
import ReactLoading from "react-loading";
interface JsonResponse {
  image_format: string;
  image_gallery: {
    count: number;
    image: {
      [key: string]: string;
    };
  };
  num_clusters: number;
  word_clouds: {
    address: string;
    count: number;
    domain: string;
    email: string;
    keyword: string;
    phone: string;
  };
}
interface cloudProperties {
  clabel: string;
  wordCloudStringJSON: JsonResponse | null;
}


export default function AttributePaneGallery(cloudproperties: cloudProperties) {

  const imageContainer = document.getElementById("image-container");

  const numImagesPerRow = 4; // Adjust this value according to your layout
  const containerWidth = 1000; // Adjust this value according to your layout
  const imageWidth = containerWidth / numImagesPerRow - 10;

  return (
    <div style={{ margin: "1%" }}>
    <h2><strong>WordCloud Image</strong></h2>
    {cloudproperties.wordCloudStringJSON !== null ? (
      <>
        {(cloudproperties.wordCloudStringJSON.word_clouds.count !== 0) ? 
          <img alt='Selected WordCloud' src={cloudproperties.wordCloudStringJSON.word_clouds.domain} style={{height: 'auto' }}/>  : 
          <h3> No WordCloud to Display.</h3>
        }
        <h2><strong>Image Gallery</strong></h2>
        <div id="image-container" style={{ display: 'flex', flexWrap: 'wrap', overflowX: 'auto', gap: '10px' }}>
          {/* Iterate through image URLs and create img elements */}
          {cloudproperties.wordCloudStringJSON.image_gallery !== null ?  
            (Object.entries(cloudproperties.wordCloudStringJSON.image_gallery.image).length !== 0 ?
              Object.entries(cloudproperties.wordCloudStringJSON.image_gallery.image).map(([imageName, imageUrl]) => (
                <img key={imageName} src={imageUrl} alt={imageName} style={{ width: `${imageWidth}px`, height: 'auto', margin: '5px', borderRadius: '8px' }} />
              )) :
              <h3>No Images to display</h3>
            ) :
            <h3>No Images to display</h3>
          }
        </div>
      </>
    ) :
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <h1>Loading...</h1>
        <ReactLoading type="bars" color="#0000FF" width="100px" />
      </div>
    }
  </div>
  
  
    
  //   <Grid borderRadius="md" templateColumns="repeat(3, 1fr)" justifyItems='center' alignItems='center'>
  //   <GridItem colSpan={3}>
  //     <Image src={require('assets/img/APGtest/catWordCloud.png')} alt={'testWordCloud'}/>
  //   </GridItem>
  //   <GridItem>
  //     <Image src={require('assets/img/APGtest/cat1.png')} alt={'cat1'}/>
  //   </GridItem>
  //   <GridItem>
  //     <Image src={require('assets/img/APGtest/cat2.png')} alt={'cat2'}/>
  //   </GridItem>
  //   <GridItem>
  //     <Image src={require('assets/img/APGtest/cat3.png')} alt={'cat3'}/>
  //   </GridItem>
  //   <GridItem>
  //     <Image src={require('assets/img/APGtest/cat4.png')} alt={'cat4'}/>
  //   </GridItem>
  // </Grid> 
  );
}
