import React, { FC, useEffect, useState, Dispatch, SetStateAction} from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { SigmaContainer, ZoomControl, FullScreenControl } from "@react-sigma/core"; 
import { omit, mapValues, keyBy, constant } from "lodash";
import Graph from "graphology";
import { parse } from "graphology-gexf/browser";
import * as sigmaDemoTypes from "./types";
import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";
import GraphSettingsController from "./GraphSettingsController";
import GraphEventsController from "./GraphEventsController";
import GraphDataController from "./GraphDataController";
// import DescriptionPanel from "./DescriptionPanel";
import { Dataset, FiltersState } from "./types";
import ClustersPanel from "./ClustersPanel";
import SearchField from "./SearchField";
import drawLabel from "./canvas-utils";
import GraphTitle from "./GraphTitle";
import TagsPanel from "./TagsPanel";
import ReactLoading from "react-loading";
import { ISigmaGraphInfo } from "./index";
import { GrClose } from "react-icons/gr";
import { BiRadioCircleMarked, BiBookContent, BiHome } from "react-icons/bi";
import { BsArrowsFullscreen, BsFullscreenExit, BsZoomIn, BsZoomOut } from "react-icons/bs";
import apiLogin from "api/apiLogin";
import './Network.css'
import axios from 'axios';

interface NetworkProps{
  networkName: string;
  num_clusters?: number | null;
}

//Later move these arrays to a useState and update using a useEffect, reference using a callback ex. React.Dispatch<React.SetStateAction<Array<any> | null>>)
let listOfNetworks: Array<any> = []
let listOfNetworksAttributes: NetworkProps[] = [];

  const apiGetNetworks = async(setNetworksCallBack: React.Dispatch<React.SetStateAction<Array<any> | null>>) =>{
    return await axios.get('https://api.ai-snips.io/api/networks/',
    {
      headers: {
        accept : 'application/json',
        Authorization : sessionStorage.getItem("authToken"),
      },
      timeout: 10000
    })
    .then(function(response){
      // console.log(response);
      listOfNetworks = response.data;
      setNetworksCallBack(listOfNetworks);
      return response.data;
    })
    .catch( async function(error){
        await new Promise<void>((resolve,reject) => {
          setTimeout(() => {
            apiGetNetworks(setNetworksCallBack);
            resolve();
          }, 3000)
        })
      console.log(error);
    })
  };

  function parseNetworkData(){
    let listOfNetworksNames: Array<string> = [];
    // console.log("This is network names");
      for (let i = 0; i < listOfNetworks.length;i++){
        const item = listOfNetworks[i];
        const str = item.circle_pack_gexf;
        const parts = str.split('/');
        const NetworkName = parts[2];
        listOfNetworksNames.push(NetworkName);
      }
      // console.log(listOfNetworksNames);
      return listOfNetworksNames
    }

  //Later on have this merge with function above to just return an item that has an interface of all the properties of a network
  function parseNetworkDataAttributes(){
    // console.log("This is network names");
      for (let i = 0; i < listOfNetworks.length;i++){
        const item = listOfNetworks[i];
        const str = item.circle_pack_gexf;
        const parts = str.split('/');
        const NetworkName = parts[2];
        const networkItem: NetworkProps = {
          networkName: NetworkName,
          num_clusters: item.num_clusters
        };
        listOfNetworksAttributes.push(networkItem);
      }
      // console.log(listOfNetworksNames);
      return listOfNetworksAttributes;
  }

  const findNumClustersByName = (networks: NetworkProps[] | null, name: string): boolean => {
    if (networks === null) { return false; }
    const foundNetwork = networks.find(network => network.networkName === name);
    if (foundNetwork) {
      if (foundNetwork.num_clusters !== 0 ) {
        return true;
      }
      return false;
    }
    return false; // Return false if the network with the given name is not found
  };
      
    const timeout = (prom: Promise<any>, time: number) =>
    Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time))]);      
    

    function LoadingBanner(props: any) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <h1>
            Loading...
          </h1>
          <ReactLoading type="bars" color="#0000FF" width="100px" />
        </div>
      );
    }

    //Works but when graph and operations are loaded need to be controlled to make use of it
    // function ErrorBanner(props: { error: any }) {
    //   return (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //       <h1>
    //         {"Error: "}
    //         {typeof props.error !== "object"
    //           ? props.error.toString()
    //           : props.error.message}
    //       </h1>
    //     </div>
    //   )
    // }

    interface DropDownProps {
      arr?: null | string[]; // Define the type of arr prop
      myVal?: string;
      setMyCallback?: Dispatch<SetStateAction<string>>;
  //    callBack: useState<string | null>(null);
    }


    // @ts-ignore
    const DropDown1: React.FC<DropDownProps> = ({ arr, myVal, setMyCallback }) => {
      let defaultVal: string | undefined = ''
      // console.log(sessionStorage.getItem("selectedRowNetwork"))
      if (myVal !== ''){
        defaultVal = myVal;
        sessionStorage.setItem("selectedRowNetwork", myVal!);
      }
      if (myVal === '' && sessionStorage.getItem("selectedRowNetwork") != null){
        defaultVal = sessionStorage.getItem("selectedRowNetwork")!;
      }

      const [selectedValue, setSelectedValue] = useState<string>(defaultVal!);
    
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (myVal === '' && sessionStorage.getItem("selectedRowNetwork") != null){
          setSelectedValue(sessionStorage.getItem("selectedRowNetworK")!);
          if (setMyCallback){ setMyCallback(sessionStorage.getItem("selectedRowNetwork")!)}
        }
        else{
          setSelectedValue(event.target.value);
          if (setMyCallback){ setMyCallback(event.target.value); }
        }

      };
      return (
        <div>
          <select id = "dropdown1" value={selectedValue} onChange={handleChange}>
          <option value="" disabled>Select a Network</option>
          {arr!.toReversed().map((item,index) => (
          <option key = {index}>{item}</option>
          ))}
          </select>
        </div>
      );
    };


    const DropDown2: React.FC<DropDownProps> = ({myVal,setMyCallback}) => {
      let defaultVal: string | undefined = 'radial'
      if (myVal !== ''){
        defaultVal = myVal;
      }
      const [selectedValue, setSelectedValue] = useState<string>(defaultVal!);
    
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedValue(event.target.value);
          if (setMyCallback){ setMyCallback(event.target.value); }
        
      };
      return (
        <div>
          <select id = "dropdown2" value={selectedValue} onChange={handleChange}>
            <option value="" disabled>Select a Graph Type</option>
            <option value="radial">Radial</option>
            <option value="circle_pack">Circle Pack</option>
          </select>
        </div>
      );
    };

  const apiGetNetworkData = async (fn: string, networkView: string, networkName: string, setErrorMsgCallback: React.Dispatch<React.SetStateAction<string | null>>, timeout_ms: number = 10000) => {
    const apiString: string = 'https://api.ai-snips.io/api/networks/';
    const viewStringPrepend: string = '?view=';
    try {
      const response = await axios.get(apiString + networkName + viewStringPrepend + networkView,
      {
        headers: {
          Authorization : sessionStorage.getItem("authToken"),
        },
        timeout: timeout_ms
      })
      // console.log("Response from API backend");
      // console.log(response.data);
      return response.data;
    } catch (error) {
      await new Promise<void>((resolve,reject) => {
        setTimeout(() => {
          apiGetNetworkData(fn, networkView, networkName, setErrorMsgCallback, timeout_ms);
          resolve();
        }, 3000)
      })
      console.log('Error is ', error)
    }
  } 

    const fetchData = async (fn: string, setErrorMsgCallback: React.Dispatch<React.SetStateAction<string | null>>, timeout_ms: number = 10000) => {
      // stateless function
      // (in case React.Strict mode is on, in dev mode components will render twice)
      //const user: IUser = getUser();
      let data: any = null;
    
      try {
      //data = await StaticApi.GetFile(user, fn, timeout_ms);
      //   console.log(`Data fetched from ${fn}`);
        return data.data;
      }
      catch (error: any) {
        //console.error("API call failed: " + fn + "\n" + error);
        setErrorMsgCallback(null);
      }
    }

    // interface IUpdateButtonProps {
    //   setNetworksCallback?: React.Dispatch<React.SetStateAction<Array<any> | null>>;
    //   setLoggedInCallback?: React.Dispatch<React.SetStateAction<boolean | null>>;
    //   setNetworkNamesCallback?: React.Dispatch<React.SetStateAction<Array<string> | null>>;
    // }

    //Works, but not used right now. Good for debugging
    // const UpdateButton: React.FC<IUpdateButtonProps> = ({setNetworksCallback, setLoggedInCallback, setNetworkNamesCallback}) => {
    //   const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
    //     e.preventDefault();
    //     apiLogin(setLoggedInCallback!)
    //     apiGetNetworks(setNetworksCallback!)
    //     setNetworkNamesCallback!(parseNetworkData());
    //   };
    
    //   return <button onClick={handleMouseEvent}>Update</button>;
    // };


    const Networks: FC<ISigmaGraphInfo> = ({ graph_fn, config_fn }) => {

      const TIMEOUT_INTERVAL: number = 10000;
    
      const [ showContents, setShowContents ] = useState(false); //For .gexf rendering
      const [ dataReady, setDataReady ] = useState(false); //For .gexf rendering
      const [ dataset, setDataset ] = useState<Dataset | null>(null); //For .gexf rendering
      const [ filtersState, setFiltersState ] = useState<FiltersState>({
        clusters: {},
        tags: {},
      });
      const [ networksData, setNetworksData ] = useState<Array<Object> | null>([]); //Response coming from /Networks Call from API
      const [ hoveredNode, setHoveredNode ] = useState<string | null>(null); //Current hovered node, handled by sigma-core
      const [ graphData, setGraphData ] = useState<string | null>(null); //.gexf data for selected graph
      const [ selectedName, setSelectedName ] = useState(''); //Selected Network in first Dropdown
      const [ selectedOption, setSelectedOption ] = useState('radial'); //Selected Graph Type in Second Dropdown
      const [ configData, setConfigData ] = useState<string | null>(null); //Will be implemented later
      const [ errorMsg, setErrorMsg ] = useState<string | null>(null); //Error message for error banner
      const [ isLoading, setIsLoading ] = useState(false); //Loading state before .gexf file is parsed and rendered
      const [ networkNames, setNetworkNames ]= useState<Array<string> | null>([]); //List of network names for dropdown and api calls
      const [ isLoggedIn, setIsLoggedIn ]= useState<boolean | null>(false); //Whether or not is logged in on api backend
      const [ networkAttributes, setNetworkAttributes ] = useState<NetworkProps[] | null>(null);
      const [ hasClusters, setHasClusters ] = useState<boolean>(false);
      const [navimg, setNavImg] = useState(require('../../img/NodeExplanation.png'));

      const { user } = useAuthenticator((context) => [context.user]);
      const email: string = user['attributes']!['email'];
      const password: string = user['attributes']!['custom:password'];
    
      sessionStorage.setItem("useremail",email);
      sessionStorage.setItem("password",password); 

      useEffect(() => {
        apiLogin(setIsLoggedIn)
          return () => {}
      },[]);

      useEffect(() => {
        if (isLoggedIn) apiGetNetworks(setNetworksData);
        },[isLoggedIn]);

      useEffect(() => {
        if (Array.isArray(listOfNetworks)){
          setNetworkNames(parseNetworkData());
          setNetworkAttributes(parseNetworkDataAttributes());
          if (sessionStorage.getItem("selectedRowNetwork") !== null) setSelectedName(sessionStorage.getItem("selectedRowNetwork")!)
          }
        },[networksData]);
  
      useEffect(() => {
        if (findNumClustersByName(networkAttributes, selectedName) && isLoggedIn){
          setHasClusters(true);
        }
        else setHasClusters(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedName, dataReady])

      useEffect(() => {    
        setIsLoading(true);
        setDataset(null);
        setDataReady(false);
        if (isLoggedIn && Array.isArray(listOfNetworks) && selectedName){
          timeout(Promise.all([
            apiGetNetworkData("test", selectedOption, selectedName, setErrorMsg, TIMEOUT_INTERVAL),
            fetchData(config_fn, setErrorMsg, TIMEOUT_INTERVAL)
          ])
          , TIMEOUT_INTERVAL)
            .then((res) => {
              setGraphData(res[0]);
              setConfigData(res[1]);
              return res;
            })
            .then((res) => {
              setDataReady(false);
              const graph = parse(Graph, res[0]);
              // Let's wrangle the GEXF file to match with the sigma.js demo Dataset interface
              // The plan: loop over the gexf graphology object, grabbing the nodes/edge attributes needed in the Dataset interface, matching the dataset.json Demo example
              // See https://graphology.github.io/ for the graph interface
              let nodes: Dataset['nodes'] = [];  // all the NodeData objects
              let edges: Dataset['edges'] = [];
              let clusters: Dataset['clusters'] = [];
              let tags: Dataset['tags'] = [];
      
              graph.forEachNode((node, attributes) => {
                let _node = {} as sigmaDemoTypes.NodeData;
    
                _node.key = node;
                _node.x = attributes.x;
                _node.y = attributes.y;
                _node.analyst_recommendation = attributes.analyst_recommendation;
                _node.cft_lot_number = attributes.cft_lot_number;
                _node.city = attributes.city;
                _node.company_name = attributes.company_name;
                _node.domain = attributes.domain;
                _node.drug_currency = attributes.drug_currency;
                _node.drug_dosage = attributes.drug_dosage;
                _node.drug_dosage_count = attributes.drug_dosage_count;
                _node.drug_format_advertised = attributes.drug_format_advertised;
                _node.drug_offered = attributes.drug_offered;
                _node.drug_price = attributes.drug_price;
                _node.email = attributes.email;
                _node.ground_truth_cluster_id = attributes.ground_truth_cluster_id;
                _node.historic_intelligence = attributes.historic_intelligence;
                _node.image_of_product_displayed = attributes.image_of_product_displayed;
                _node.indicator_reason = attributes.indicator_reason;
                _node.investigator_comments = attributes.investigator_comments;
                _node.investigator_decision = attributes.investigator_decision;
                _node.keyword = attributes.keyword;
                _node.lead_status = attributes.lead_status;
                _node.lead_type = attributes.lead_type;
                _node.network_affiliations = attributes.network_affiliations;
                _node.no_rx_offered = attributes.no_rx_offered;
                _node.onion_url = attributes.onion_url;
                _node.other_contact_details = attributes.other_contact_details;
                _node.owner_name = attributes.owner_name;
                _node.packaging = attributes.packaging;
                _node.phone = attributes.phone;
                _node.platform_market = attributes.platform_market;
                _node.postal_code = attributes.postal_code;
                _node.prioritization_category = attributes.prioritization_category;
                _node.prioritization_score = attributes.prioritization_score;
                _node.purported_country_of_operation = attributes.purported_country_of_operation;
                _node.quantity_offered = attributes.quantity_offered;
                _node.region = attributes.region;
                _node.risk_score = attributes.risk_score;
                _node.search_term = attributes.search_term;
                _node.seller_name = attributes.seller_name;
                _node.shipping_time = attributes.shipping_time;
                _node.social_media_profile_name = attributes.social_media_profile_name;
                _node.social_media_profile_url = attributes.social_media_profile_url;
                _node.street_address = attributes.street_address;
                _node.suspicious_price = attributes.suspicious_price;
                _node.take_down_target = attributes.take_down_target;
                _node.test_purchase_id = attributes.test_purchase_id;
                _node.test_purchase_recommendation = attributes.test_purchase_recommendation;
                _node.uid = attributes.uid;
                _node.url = attributes.url;
                _node.webpage_description = attributes.webpage_description;
                _node.webpage_title = attributes.webpage_title;
                _node.whois_report_details = attributes.whois_report_details;
                _node.authority = attributes.authority;
                _node.betweeness_centrality = attributes.betweeness_centrality;
                _node.closeness_centrality = attributes.closeness_centrality;
                _node.cluster_id = attributes.cluster_id;
                _node.component_id = attributes.component_id;
                _node.degree = attributes.degree;
                _node.eccentricity = attributes.eccentricity;
                _node.eigencentrality = attributes.eigencentrality;
                _node.harmonic_closeness_centrality = attributes.harmonic_closeness_centrality;
                _node.hub = attributes.hub;
                _node.id = attributes.id;
                _node.label = attributes.label;
                _node.pageranks = attributes.pageranks;
                _node.text_available = attributes.text_available;
                _node.triangles = attributes.traingles;
                _node.webpage_title_out = attributes.webpage_title_out;
                _node.weighted_degree = attributes.weighted_degree;
                

                // console.log(attributes);
                // console.log(node);
                nodes.push(_node);
                // console.log(nodes[0])
                let _cluster = {} as sigmaDemoTypes.Cluster;
                const clusterIsPushed = clusters.find((cluster) => cluster.key === attributes.cluster_id);
                if (!(clusterIsPushed)) {
                  _cluster.key = attributes.cluster_id;
                  _cluster.color = attributes.color;
                  _cluster.clusterLabel = attributes.cluster_id;
      
                  clusters.push(_cluster);
                }
      
                let _tag = {} as sigmaDemoTypes.Tag;
                const tagIsPushed = tags.find((tag) => tag.key === _node.uid);
                if (!(tagIsPushed)) {
                  _tag.key = _node.uid;
                  _tag.image = "";
      
                  tags.push(_tag);
                }
              });
      
              graph.forEachEdge((edge, attributes, source, target) => {
                type _edge = [string, string];
                // eslint-disable-next-line @typescript-eslint/no-redeclare
                let _edge = [source, target] as _edge;
      
                edges.push(_edge);
              });
      
              var dataset = {} as Dataset;
              dataset.nodes = nodes;
              dataset.edges = edges;
              dataset.clusters = clusters;
              dataset.tags = tags;
              setDataset(dataset);
              setFiltersState({
                clusters: mapValues(keyBy(dataset.clusters, "key"), constant(true)),
                tags: mapValues(keyBy(dataset.tags, "key"), constant(true)),
              });
              requestAnimationFrame(() => {
                setDataReady(true);
                setIsLoading(false);
              }
              );
            })
            .catch((error: any) => {
              setErrorMsg(error);
              console.error("Dataset loading failed: " + errorMsg);
              setIsLoading(false);
            });
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [networkNames,selectedName,selectedOption, networksData, isLoggedIn, errorMsg]);

      return (
        <div id = "NetworkBody">
          <div id='NetworkHeader'>
            <h1>Network Visualization</h1>
            <div id='NetworkJobsCorner'>
              <div>
                <DropDown1 arr = {networkNames} myVal={selectedName} setMyCallback={setSelectedName}></DropDown1>
                <DropDown2 myVal={selectedOption} setMyCallback={setSelectedOption}></DropDown2>
                {selectedName && <p>Network for: {selectedName} Type: {selectedOption}</p>}
              </div>
            </div>
          </div>
        {/* // this container must have id app-root (see responsiveness section in Root.scoped.css) */}
        {(dataset && selectedName && selectedOption && dataReady) ? (            
          <div id='NetworkCanvas'>
            {(isLoading && !dataReady ) ? 
              <div> <LoadingBanner /> </div> :  
              <div id="app-root" className={showContents ? "show-contents" : ""}>
                <SigmaContainer
                  style = {{height:"100vh", width: "100%"}}
                  graph={Graph}
                  settings={{
                    nodeProgramClasses: { image: getNodeProgramImage() },
                    labelRenderer: drawLabel,
                    defaultNodeType: "image",
                    defaultEdgeType: "line",
                    labelDensity: 0.07,
                    labelGridCellSize: 60,
                    labelRenderedSizeThreshold: 15,
                    labelFont: "Lato, sans-serif",
                    zIndex: true,
                  }}
                  className="react-sigma">
                  <GraphSettingsController hoveredNode={hoveredNode} />
                  <GraphEventsController setHoveredNode={setHoveredNode} />
                  <GraphDataController dataset={dataset} filters={filtersState} />

                  {dataReady && (
                    <>
                      <div className="controls" >
                        <div className="ico">
                          <button
                            type="button"
                            className="show-contents"
                            onClick={() => setShowContents(true)}
                            title="Show caption and description"
                          >
                            <BiBookContent />
                          </button>
                        </div>
                        <FullScreenControl className="ico">
                          <BsArrowsFullscreen />
                          <BsFullscreenExit />
                        </FullScreenControl>
                        <ZoomControl className="ico">
                          <BsZoomIn />
                          <BsZoomOut />
                          <BiHome />
                        </ZoomControl>
                      </div>
                      <div className="contents">
                        <div className="ico">
                          <button
                            type="button"
                            className="ico hide-contents"
                            onClick={() => setShowContents(false)}
                            title="Show caption and description"
                          >
                            <GrClose />
                          </button>
                        </div>
                        <GraphTitle filters={filtersState} graphString = {selectedName}/>
                        <div className="panels">
                          <SearchField filters={filtersState} />
                          {/* <DescriptionPanel /> */}
                          <ClustersPanel
                            selectedNetwork = {selectedName}
                            _hasCluster = {hasClusters}
                            clusters={dataset.clusters}
                            filters={filtersState}
                            setClusters={(clusters) =>
                              setFiltersState((filters) => ({
                                ...filters,
                                clusters,
                              }))
                            }
                            toggleCluster={(cluster) => {
                              setFiltersState((filters) => ({
                                ...filters,
                                clusters: filters.clusters[cluster]
                                  ? omit(filters.clusters, cluster)
                                  : { ...filters.clusters, [cluster]: true },
                              }));
                            }}
                          />
                          <TagsPanel
                            tags={dataset.tags}
                            filters={filtersState}
                            setTags={(tags) =>
                              setFiltersState((filters) => ({
                                ...filters,
                                tags,
                              }))
                            }
                            toggleTag={(tag) => {
                              setFiltersState((filters) => ({
                                ...filters,
                                tags: filters.tags[tag] ? omit(filters.tags, tag) : { ...filters.tags, [tag]: true },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )
                  }
                </SigmaContainer>
              </div>}
          </div> ): 
          (selectedName === '') ?
          <>
          <div id = 'NetworkCanvas'>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} > <h2 style = {{fontSize:"2em"}} >Please Select a Network</h2></div>
          </div>
          </> : 
          <div id = 'NetworkCanvas'>
          <div> <LoadingBanner/> </div>
       </div>
          }
       <div id='NetworkText' style={{display: 'flex', alignItems: ''}}>
          <div style={{flex: '1', marginRight: '10%'}}>
              <h2>Notes for Interpreting the Network</h2>
              <ul>
                  <li>Each dot (node) in the network corresponds to a particular URL in a Leadsheet.</li>
                  <li>Each line (edge) represents a connection between nodes made by our similarity model.</li>
                  <li>Each color corresponds to a cluster which has been identified using our community detection algorithm.</li>
                  <li>The nodes within a cluster are ordered by <a href='https://en.wikipedia.org/wiki/Betweenness_centrality'>Betweenness Centrality</a></li>
                  <ul>
                      <li>Betweenness Centrality is a measure of how important a particular node is with respect to the rest of the cluster.</li>
                      <li>The nodes closest to the center have the highest Betweenness Centrality</li>
                  </ul>
              </ul>
          </div>
          <div style={{flex: '1'}}>
              <h2>Node Hover and Popout on Click Example</h2>
              <img src={navimg} alt="Node popout"/>
          </div>
        </div>
      </div>
    );
  };


export default Networks;