import axios from 'axios';

function convertJobIdstoString(jobIds){
    const queryString =jobIds.map(id=> `ids=${id}`).join('&');
    return queryString + '&';
}
function checkOptions(option){
  if (option === 'yes') return true;
  else return false;
}

const apiDeleteJobs = async (jobIds, opt1, opt2, periodic) => {
    const apiString = periodic ? 'https://api.ai-snips.io/api/jobs/periodic/delete?' : 'https://api.ai-snips.io/api/jobs/delete?';
    const jobsString = convertJobIdstoString(jobIds);
    const opt1String = checkOptions(opt1)? 'delete_leadsheet=true&':'delete_leadsheet=false&';
    const opt2String = checkOptions(opt2)? 'delete_scraperjobs_data=true&':'delete_scraperjobs_data=false&';
    const dry_runString = 'dry_run=false';
    return await axios.delete(apiString+jobsString+opt1String+opt2String+dry_runString,
    {
      headers: {
        accept : 'application/json',
        Authorization : sessionStorage.getItem("authToken"),
      },
      timeout: 10000
    })
    .then(function(response){
      if (response.status === 200){
        console.log(response)
      } 
      // console.log(response);
    })
    .catch(async function(error) {
      console.log("There was an error deleting data");
    //   await new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       apiGetJobs(setDashboardData);
    //       resolve();
    //     }, 3000);
    //   });
      console.log(error);
    });
  }

export default apiDeleteJobs;