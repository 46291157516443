import React from 'react';
import IntakeFormBuilder from "../../components/intakeFormBuilder/intakeFormBuilder"

const filePath = require('./demoXML.xml')

const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log("Submitted:", data);
  };

export default function DemoIntakeForm () {

    return <IntakeFormBuilder xmlFilePath={filePath} handleSubmit={handleSubmit} title={"Demo Inake Form"}/>
}