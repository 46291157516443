import React, { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ScaleLoader from "react-spinners/ScaleLoader";
import Papa from 'papaparse';
import axios from 'axios';
import apiLogin from 'api/apiLogin';

sessionStorage.setItem("jobName", "");

//Handle Checkbox check/uncheck
const handleCheckChange = (isChecked, setIsChecked) =>{
  setIsChecked(!isChecked);
}

const LeadsheetUploadForm = () => {

  const [file, setFile] = useState(null);
  const [jobName, setJobName] = useState('');
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false)
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [loggedIn, setLoggedIn ] = useState(false);
  const acceptedFileTypes = ".csv"

  const { user } = useAuthenticator((context) => [context.user]);
  const email = user['attributes']['email']
  const password = user['attributes']['custom:password']

  sessionStorage.setItem("useremail",email);
  sessionStorage.setItem("password",password); 
  
  async function handleFileChange (event) {
    setError(null)
    if (event.target.files[0]) {
      try {
        // Basic validation checking
        //    1. If the csv contains url, lead_type, and uid columns
        //    2. For duplicate column names
        Papa.parse(event.target.files[0],{
          complete: (results) => {
            // console.log(results)
            const headers = results.data[0]
            if (!headers.includes('url')) {
              setError('Invalid leadsheet: Missing column labeled: "url"')
            }
            if (!headers.includes('lead_type')) {
              setError('Invalid leadsheet: Missing column labeled: "lead_type"')
            }
            if (!headers.includes('uid')) {
              setError('Invalid leadsheet: Missing column labeled "uid"')
            }
            if (new Set(headers).size !== headers.length){
              setError('Invalid leadsheet: Contains duplicate column names')
            }
          }})
      } catch (e) {
        setError(e.message)
      }
      setFile(event.target.files[0]);
      }
  };

  //Function to upload leadsheet to API. Assumes leadsheet is already selected, tied to upload button. 
  const apiUploadLeadSheet = async() =>{
    const data = new FormData();
    data.append('file', file);
    setLoading(true);
    const apiString = 'https://api.ai-snips.io/api/leadsheets/upload'
    const periodicStringAppend = '?start_job=true&periodic=true'
    return await axios.postForm((isChecked ? apiString+periodicStringAppend: apiString), data,
    {
      headers: {
        accept : 'application/json',
        Authorization : sessionStorage.getItem("authToken"),
        'Content-Type' : 'multipart/form-data'
      },
      timeout: 10000
    })
    .then(function(response){
      if (response.data.success === true && response.status === 202){
        console.log("Successfully Uploaded Leadsheet.");
        setJobName(response.data.msg);
        setFile(null)
        setSuccessful(true);
        setLoading(false);
      } 
      // console.log(response);
    })
    .catch(function(error){
      console.log("There was an error uploading. Please check Jobs Dashboard before trying again.")
      console.log(error);
      setLoading(false);
      setSuccessful(false);
    });
  }

  //Login on page load
  useEffect(() => {
    apiLogin(setLoggedIn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  return (
    <div id='uploadForm'>
      <div>
        <h1>Leadsheet Upload</h1>
      </div>
      <div id='uploadBody'>
        <div id='uploadRequirements'>
        <h2>Requirements</h2>
          <ul>
            <li>Must be a .csv file with a 25MB limit.</li>
            <li>Must contain column headers: url, lead_type, and uid (case sensitive).</li>
            <li>Cannot contain duplicate column names.</li>
            <li>Periodic jobs will run once every week at the same time.</li>
          </ul>
        </div>
      {loading ? (
      <div id='uploadItem'>
          <ScaleLoader color='#ffffff' style={{justifyContent : 'center'}}/>
      </div>
      ) : (
        <div id='uploadItem'>
          <input type="file" accept={acceptedFileTypes} onChange={handleFileChange} style={{textShadow : "2px 2px 4px rgb(0, 0, 0), 0px 0px 20px rgba(0, 0, 0, 0.5)", margin: "5%", width: "100%"}}/>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button style={{ margin: '5%'}} onClick={apiUploadLeadSheet} disabled={error}>Upload</button>
            <input type="checkbox" value={isChecked} onChange={ () => {handleCheckChange(isChecked, setIsChecked)}} style={{ marginLeft: '10px', width: '20px', height: '20px'}} />
            <p style={{ marginLeft: '5px' }}>Periodic?</p>
          </div>
          {successful && <div>{jobName}</div>}
          {error && <div>{error}</div>}
        </div>
      )}
      </div>
    </div>
  );
};

export default LeadsheetUploadForm;