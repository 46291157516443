import { useRegisterEvents, useSigma } from "@react-sigma/core"; //"react-sigma-v2";
import { FC, useEffect, useState } from "react";

function getMouseLayer() {
  return document.querySelector(".sigma-mouse");
}

function createPropertyString(obj: Object) {
  let result = '';
  for (const [key, value] of Object.entries(obj)) {
    // Exclude 'x' and 'y' properties
    if (key !== 'x' && key !== 'y' && key !== 'key') {
      // Exclude properties with NaN values
      if (value !== undefined && value !== 'NaN' && !Number.isNaN(value)) {
        const formattedValue = typeof value === 'string' ? value.replace(/<br>/g, '\n               ') : value;
        result += `${key}: ${formattedValue}\n`;
      }
    }
  }
  return result;
}

const GraphEventsController: FC<{ setHoveredNode: (node: string | null) => void; children?: React.ReactNode }> = ({ setHoveredNode, children }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();
  const [ bool, setBool ] = useState<boolean>(true);

  function boolswitch(){
    setBool(bool => !bool);
  }

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    registerEvents({
      clickNode({ node }) {
        if (!graph.getNodeAttribute(node, "hidden")) {
          console.log(graph.getNodeAttributes(node))
        //  window.open(graph.getNodeAttribute(node, "URL"), "_blank");
        // boolswitch();
        //   <Popup
        //     open = {bool} 
        //     position="right center"
        //     closeOnDocumentClick
        //   >
        // <div className="modal">
        //   <a className="close" onClick={setBool(false)!}>
        //     &times;
        //   </a>
        //   Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae magni
        //   omnis delectus nemo, maxime molestiae dolorem numquam mollitia, voluptate
        //   ea, accusamus excepturi deleniti ratione sapiente! Laudantium, aperiam
        //   doloribus. Odit, aut.
        // </div>
        //   </Popup>
        // Check if the object exists before accessing its properties


          // if (graph.getNodeAttributes(node) && graph.getNodeAttributes(node).x !== undefined) {
          alert(createPropertyString(graph.getNodeAttributes(node)));
          // }
        }
      },
      enterNode({ node }) {
        setHoveredNode(node);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add("mouse-pointer");
      },
      leaveNode() {
        setHoveredNode(null);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove("mouse-pointer");
      },
    });
  }, [bool,graph,registerEvents,setHoveredNode]);

  return <>{children}</>;
};

export default GraphEventsController;
