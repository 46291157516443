import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import UserRole from "pages/UserRole";


const Navbar = () => {
  const { authStatus, signOut } = useAuthenticator((context) => [context.authStatus]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [color, setColor] = useState("#0c3678");
  const [textcolor, setTextColor] = useState("white");
  const [navimg, setNavImg] = useState(require('../img/logo_uh_red.png'));
  const [textShadow, setTextShadow] = useState('2px 2px 4px rgb(0, 0, 0), 0px 0px 20px rgba(0, 0, 0, 0.5)');
  const [anchorEl, setAnchorEl] = useState(null);
  const [iconColor, setIconColor] = useState('white'); // Default color

  useEffect(() => {
    window.addEventListener("scroll", changeColor, { passive: true });
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);


  const { user } = useAuthenticator((context) => [context.user]);
  var userRole = user?.signInUserSession?.idToken?.payload['cognito:groups'];

  function createNickname(email) {
    return email.split('@')[0]
  }
  
  var userNickname = user?.attributes?.email ? createNickname(user.attributes.email) : "User Account"

  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function formatUserRole(input) {
    var s = capitalizeFirstLetter(input)
    return s.replace(/,(.*?)(?=,|$)/g, (_, text) => ',' + text.toUpperCase());
}

  const changeColor = () => {
    if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      setColor("white");
      setTextColor("#161616");
      setNavImg(require('../img/logo_uh_secondary.png'));
      setTextShadow('none');
      setIconColor("black");
    } else if (document.documentElement.scrollTop < 49 || document.body.scrollTop < 49) {
      setColor("#0c3678");
      setTextColor("white");
      setNavImg(require('../img/logo_uh_red.png'));
      setTextShadow('2px 2px 4px rgb(0, 0, 0), 0px 0px 20px rgba(0, 0, 0, 0.5)');
      setIconColor("white");
    }
  };

  const navigate = useNavigate();
  function handleSignOut() {
    signOut();
    navigate('/');
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
    handleMenuClose(); // Close the menu when opening the modal
  };

  function check_AISNIPS_auth(role) {
    // Function to checking if a user is allowed to see AISNIPS related tabs in the Navbar
      if (typeof role !== String) {
        role = String(role)
      }
      const allowed_roles = ["admin", "researcher", "stakeholder"]
      return allowed_roles.includes(role)
  }

  function check_AAI_auth(role) {
    // Function to checking if a user is allowed to see AAI related tabs in the Navbar
      if (typeof role !== String) {
        role = String(role)
      }
      const allowed_roles = ["admin", "Collaborator,AAI"]
      return allowed_roles.includes(role)
  }

  return (
    <div>
      <nav className="nav" style={{ backgroundColor: color, color: textcolor, textShadow: textShadow }}>
        <div>
          <a href="/">
            <img
              src={navimg}
              alt="UH-logo"
              title=""
              style={{
                margin: '5px',
                height: "60px",
                display: "inline-flex",
                verticalAlign: "middle"
              }}
            />
          </a>
        </div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/About">About</Link>
          </li>
          {(authStatus !== 'authenticated') ? (
            <li style={{marginRight : 10}}>
              <Link to="/Login">Login</Link>
            </li>
          ) : (
            <>
              {(check_AISNIPS_auth(String(userRole))) && 
              <li>
                <Link to='/HowTo'>How To</Link>
              </li>}
              {(check_AISNIPS_auth(String(userRole))) && 
              <li>
                <Link to='/Documentation'>Documentation</Link>
              </li>}
              {(check_AISNIPS_auth(String(userRole))) && 
              <li>
                <Link to='/Status'>Status</Link>
              </li>}
              {(check_AISNIPS_auth(String(userRole))) && 
              <li>
                <Link to="/LeadsheetUpload">Leadsheet Upload</Link>
              </li>}
              {(check_AISNIPS_auth(String(userRole))) && 
              <li>
                <Link to="/JobsDashboard">Jobs Dashboard</Link>
              </li>}
              {(check_AISNIPS_auth(String(userRole))) && 
              <li>
                <Link to="/Networks">Networks</Link>
              </li>}
              {(check_AAI_auth(String(userRole))) && 
              <li>
                <Link to="/AAI">AAI</Link>
              </li>}
              <li style={{marginRight : 10}} >
                <IconButton onClick={handleMenuOpen} style={{ display: 'flex', alignItems: 'center', height: '100%', borderRadius: 0,}}>
                  <PersonIcon sx={{ color: iconColor, fontSize: 30 }} /> 
                  <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                      <div style={{ fontSize: '14px',   textShadow: textShadow, color: iconColor , height:'16px'}}>{userNickname}</div>
                      <div style={{ fontSize: '11px',   textShadow: textShadow, color: iconColor }}>{formatUserRole(String(userRole))}</div>
                  </div>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={openModal}><i className="fa fa-user" style={{paddingRight:'14px'}}></i> My Profile</MenuItem>
                  <MenuItem onClick={() => { handleMenuClose(); handleSignOut(); }}><i className="fa fa-sign-out-alt" style={{paddingRight:'14px'}}></i> Sign Out</MenuItem>
                </Menu>
              </li>
            </>
          )}
        </ul>
      </nav>
      <Outlet />
      <UserRole isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> 
    </div>
  );
};

export default Navbar;