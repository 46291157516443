import './App.css';
import { useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

import Home from './pages/Home';
// import Selector from 'pages/Selector';
import About from './pages/About';
import Navbar from './components/Navbar';
import HowTo from './pages/HowTo';
import LeadsheetUploadForm from './pages/LeadsheetUpload/LeadsheetUploadForm'
import JobsDashboard from './pages/JobsDashboard/JobsDashboard'
import Networks from './pages/Networks/Networks'
import Stats from './pages/Stats/Stats';
import Documentation from 'pages/Documentation';
import Status from './pages/Status';
import Footer from './components/Footer'
import { AuthComponents, formFields } from './components/AuthComponents';
import AAI from 'pages/AAI/AAI';

Auth.configure(awsconfig);




let nAWSVid = false;

const setAWSVidVariable = (value) => {
  nAWSVid = value;
};

export { nAWSVid, setAWSVidVariable };


function App() {

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  

  useEffect(() => {
    Hub.listen("auth", (data) => {
      if (data?.payload?.event === "signIn") {
        navigate("/Selector");
      }
    });
  }, []);

  return (
      <div>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="About" element={<About />} />
            {/* There is probably a better way to do this, amplify's withAuthenticator HOC has similar functionallity but makes it much harder to customize UX -AC */}
            <Route path="Login" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <Navigate to="/"/>} />
            {/* <Route path="Selector" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <Selector/>} /> */}
            <Route path="HowTo" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <HowTo />}  />
            <Route path="LeadsheetUpload" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <LeadsheetUploadForm />}  />
            <Route path="JobsDashboard" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <JobsDashboard />} />
            <Route path="Networks" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <Networks />} />
            <Route path="Stats" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <Stats />} />
            <Route path="Documentation" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <Documentation />} />
            <Route path="Status" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <Status />} />
            <Route path="AAI" element={authStatus !== 'authenticated' ? <Authenticator hideSignUp={true} formFields={formFields} components={AuthComponents}/> : <AAI />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
        <Footer />
      </div>
  );
}

export default App;
