import axios from 'axios';

//Let's you get list of jobs from api backend available to logged in user
const apiGetJobs = async(setDashboardData) =>{
  return await axios.get('https://api.ai-snips.io/api/jobs/',
  {
    headers: {
      accept : 'application/json',
      Authorization : sessionStorage.getItem("authToken"),
    },
    timeout: 10000
  })
  .then(function(response){
    if (response.status === 200){
      // console.log("Successfully Retrieved Dashboard Data.");
      setDashboardData(response.data);
    } 
    // console.log(response);
  })
  .catch(async function(error) {
    console.log("There was an error retrieving dashboard data.");
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        apiGetJobs(setDashboardData);
        resolve();
      }, 3000);
    });
    console.log(error);
  });
}
export default apiGetJobs