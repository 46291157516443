import { React } from 'react';
// import { useAuthenticator } from '@aws-amplify/ui-react';
import { BuildStatusBadge } from "react-build-status-badge";

export default function Status() {

// const { user } = useAuthenticator((context) => [context.user]);
// console.log(user)

  return (
    <div className="HowTo-Container">
      <h1>AI-SNIPS Status Badges</h1>
      <div>
          <BuildStatusBadge
            interval={30000}
            src="https://api.ai-snips.io/api/manage/status/aisnips-webapi-prod-env.svg"
            alt="WebApi Production Status Badge" />
          {/* <BuildStatusBadge
            src="https://api.ai-snips.io/api/manage/status/aisnips-web-prod-env.svg"
            alt="WebApp Production Status Badge" /> */}
          <BuildStatusBadge
            interval={30000}
            src="https://api.ai-snips.io/api/manage/status/aisnips-workerapi-prod-env.svg"
            alt="Network (Worker) Production Status Badge" />
          <BuildStatusBadge
            interval={30000}
            src="https://api.ai-snips.io/api/manage/status/aisnips-scraper-prod-env.svg"
            alt="Scraper Production Status Badge" />
            <div style={{ position: "relative", margin: "12px", padding:"10px" }}>
              {/* <BuildStatusBadge
                src="https://api.ai-snips.io/api/manage/status/aisnips-webapi-stag-env.svg"
                alt="WebApi Staging Status Badge" /> */}
              {/* <BuildStatusBadge
                src="https://api.ai-snips.io/api/manage/status/aisnips-web-stag-env.svg"
                alt="WebApp Staging Status Badge" /> */}
              {/* <BuildStatusBadge
                src="https://api.ai-snips.io/api/manage/status/aisnips-workerapi-stag-env.svg"
                alt="Network (Worker) Staging Status Badge" /> */}
              {/* <BuildStatusBadge
                src="https://api.ai-snips.io/api/manage/status/aisnips-scraper-stag-env.svg"
                alt="Scraper Staging Status Badge" /> */}
            </div>
      </div>
      <div>
          <h1>Zyte Status</h1>
          <p>Link to Website: <a href='https://status.zyte.com/'>https://status.zyte.com/</a></p> 
        </div>
     </div>
    )
  }