import { Amplify, Auth } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json'
import '@aws-amplify/ui-react/styles.css'
import AWS from 'aws-sdk';

class AwsUtils{
    constructor(props){
        Amplify.configure(amplifyconfig);
        this.setGroup = props.setGroup;
    }

    async getCredentials(){
        try{
            const user = await Auth.currentAuthenticatedUser()
            const usercreds = await Auth.currentCredentials(user, {});  
            return usercreds
        }
        catch(error)
        {
            console.error("Error getting credentials:", error);
            throw error
        }
    }

    async updateAWSCredentials(){
        try {
            const credentials = await this.getCredentials();
            AWS.config.update({
              region: 'us-east-1', //Currently region is hard coded to us-east-1 for this userpool but it can be adjust later
              credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken 
              }
            });
            console.log("AWS configuration updated successfully");
          } 
          catch (error) {
            console.error("Error updating AWS configuration:", error);
          }
    }

    async addUserToGroup(username,groupName){
        try {
            // Update AWS credentials 
            await this.updateAWSCredentials();
        
            // Now the AWS configuration has been updated, proceed with the operation
            const params = {
              GroupName: groupName,
              UserPoolId: "us-east-1_CLDqKn4hD",
              Username: username,
            };
            
            // Create a Cognito Identity Service Provider client, this will use configured credentials
            // The line below and updateAWSCredentials() used to be outside of this function so it would run on page load but because of the async nature of updateAWSCredentials() I opted to do it this way
            
            const cognitoISP = new AWS.CognitoIdentityServiceProvider();
            //Command that adds user from group using cognitoisp. Had to add custom policy change to allow users of this pool to use this command
            await cognitoISP.adminAddUserToGroup(params).promise();
            this.setGroup(groupName);
            this.refreshUserSession();
            // console.log(`User ${username} added to group ${groupName}`);
          } catch (error) {
            console.error(`Error:`, error);
          }
    }

    //Removes user passed based on username and groupname, can be changed later to dynamically pull UserPoolId by passing user object and grabbing from pool from useAuthenticator context
    async removeUserFromGroup(username, groupName){
        try {
        // Update AWS credentials
        await this.updateAWSCredentials();
    
        // Now the AWS configuration has been updated, proceed with the operation
        const params = {
            GroupName: groupName,
            UserPoolId: "us-east-1_CLDqKn4hD",
            Username: username,
        };
        // Create a Cognito Identity Service Provider client, this will use configured credentials
        const cognitoISP = new AWS.CognitoIdentityServiceProvider();
        //Command that removes user from group using cognitoisp. Had to add custom policy change to allow users of this pool to use this command
        await cognitoISP.adminRemoveUserFromGroup(params).promise();
        this.refreshUserSession();
        // console.log(`User ${username} removed from group ${groupName}`);
        } catch (error) {
        console.error(`Error:`, error);
        }
    };
    
    //RefreshesUserSession Otherwise user would have to login and logout to see their group change
    async refreshUserSession() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {}); // This triggers a refresh of the session token
            // console.log('User session refreshed successfully');
        } catch (error) {
            console.error('Error refreshing user session:', error);
        }
    }
}

export default AwsUtils;